// ./src/containers/ProductLandingPageContainer.js

import { connect } from "react-redux";
import { ProductLandingPage } from "../pages/ProductLandingPage";

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.csUser.user,
    tb_user_plan: state.csUser.user ? state.csUser.user.success_plan : null,
    hubFilters: state.csHub.eventHubFilters[ownProps.match.params.id],
    hubConfig: state.csHub.eventHubConfig[ownProps.match.params.id],
    topicList: state.csEvent.productTopicGroups[ownProps.match.params.id],
    listInit: state.csEvent.listInit,
    listCurrent: state.csEvent.eventListCurrent,
    timezone: state.csUser.timezone,
    language: state.csUser.language,
    hubFeaturedEvent: state.csHub.eventHubFeatured,
    notFoundId: state.csHub.hubDetailNotFound,
    hubId: ownProps.match.params.id,
    isLoading: state.csHub.isLoading,
    criticalError: state.csOther.criticalError,
    tb_id: state.csUser.user ? state.csUser.user.tb_id : null,
    favorites: state.csUser.favoritesAll,
    appConfig: state.csEvent.appConfig,
  };
};

const ProductLandingPageContainer =
  connect(mapStateToProps)(ProductLandingPage);

export default ProductLandingPageContainer;
